import 'reset-css';
import './index.css';
import './webinar-card.css';
import './conference-card.css';
import './dashboard-card.css';
import './tablet-screen.css';
import './header_landing.css';

/* svgs to use in sprites */
import symbol0 from '../assets/icons/webinar_card_icon_camera.svg';
import symbol1 from '../assets/icons/webinar_card_icon_assets.svg';
import symbol2 from '../assets/icons/webinar_card_icon_add_asset.svg';
import symbol3 from '../assets/icons/webinar_card_icon_mic.svg';
import symbol4 from '../assets/icons/webinar_card_icon_fullscreen.svg';
import symbol5 from '../assets/icons/webinar_card_icon_settings.svg';
import symbol6 from '../assets/icons/webinar_card_icon_translation.svg';

import symbol7 from '../assets/icons/webinar_card_icon_chat_send.svg';
import symbol8 from '../assets/icons/webinar_card_icon_chat_emojis.svg';
import symbol9 from '../assets/icons/webinar_card_icon_chat_attachment.svg';


import symbol10 from '../assets/icons/webinar_card_icon_assets.svg';
import symbol11 from '../assets/icons/webinar_card_icon_fullscreen_accent.svg';
import symbol12 from '../assets/icons/webinar_card_icon_share.svg';
import symbol13 from '../assets/icons/webinar_card_icon_plus.svg';
import symbol14 from '../assets/icons/webinar_card_icon_minus.svg';
import symbol15 from '../assets/icons/webinar_card_icon_checkbox.svg';
import symbol16 from '../assets/icons/webinar_card_icon_graph.svg';

import symbol17 from '../assets/icons/webinar_card_icon_reaction_boom.svg';
import symbol18 from '../assets/icons/webinar_card_icon_reaction_clap.svg';
import symbol19 from '../assets/icons/webinar_card_icon_reaction_fire.svg';
import symbol20 from '../assets/icons/webinar_card_icon_reaction_handup.svg';
import symbol21 from '../assets/icons/webinar_card_icon_reaction_heart.svg';
import symbol22 from '../assets/icons/webinar_card_icon_reaction_smile.svg';
import symbol23 from '../assets/icons/webinar_card_icon_reaction_thumbsup.svg';

import symbol24 from '../assets/icons/webinar_card_icon_record.svg';
import symbol25 from '../assets/icons/webinar_card_icon_emojis.svg';

import symbol26 from '../assets/icons/webinar_card_icon_speaker_close.svg';
import symbol27 from '../assets/icons/webinar_card_icon_speaker_fav.svg';
import symbol28 from '../assets/icons/webinar_card_icon_speaker_mic.svg';

import symbol29 from '../assets/icons/conference_card_icon_change_bg.svg';
import symbol30 from '../assets/icons/conference_card_icon_handup.svg';

import symbol31 from '../assets/icons/dashboard_card_icon_menu_window.svg';
import symbol32 from '../assets/icons/dashboard_card_icon_menu_left.svg';
import symbol33 from '../assets/icons/dashboard_card_icon_menu_right.svg';
import symbol34 from '../assets/icons/dashboard_card_icon_menu_undo.svg';
import symbol35 from '../assets/icons/dashboard_card_icon_menu_redo.svg';

import symbol36 from '../assets/icons/dashboard_card_icon_menu_plus.svg';
import symbol37 from '../assets/icons/dashboard_card_icon_menu_minus.svg';
import symbol38 from '../assets/icons/dashboard_card_icon_menu_map.svg';
import symbol39 from '../assets/icons/dashboard_card_icon_menu_help.svg';
import symbol40 from '../assets/icons/dashboard_card_icon_menu_scope.svg';

import symbol41 from '../assets/icons/dashboard_card_icon_menu_expand.svg';
import symbol42 from '../assets/icons/dashboard_card_icon_menu_headphone.svg';
import symbol43 from '../assets/icons/dashboard_card_icon_menu_timer.svg';

import symbol44 from '../assets/icons/dashboard_card_icon_menu_logo.svg';
import symbol45 from '../assets/icons/dashboard_card_icon_menu_menu.svg';

import symbol46 from '../assets/icons/dashboard_card_icon_cursor_1.svg';
import symbol47 from '../assets/icons/dashboard_card_icon_cursor_2.svg';
import symbol48 from '../assets/icons/dashboard_card_icon_cursor_3.svg';

import symbol49 from '../assets/icons/dashboard_card_icon_menu_arrow.svg';
import symbol50 from '../assets/icons/dashboard_card_icon_menu_brush.svg';
import symbol51 from '../assets/icons/dashboard_card_icon_menu_comment.svg';
import symbol52 from '../assets/icons/dashboard_card_icon_menu_cursor.svg';
import symbol53 from '../assets/icons/dashboard_card_icon_menu_jail.svg';
import symbol54 from '../assets/icons/dashboard_card_icon_menu_sticker.svg';
import symbol55 from '../assets/icons/dashboard_card_icon_menu_templates.svg';
import symbol56 from '../assets/icons/dashboard_card_icon_menu_text.svg';
import symbol57 from '../assets/icons/dashboard_card_icon_menu_upload.svg';
import symbol58 from '../assets/icons/dashboard_card_icon_menu_additional.svg';
import symbol59 from '../assets/icons/dashboard_card_icon_menu_geom.svg';

import symbol60 from '../assets/icons/benefits_icon_pruffme.svg';
import symbol61 from '../assets/icons/benefits_icon_gerb.svg';
import symbol62 from '../assets/icons/product_button_arrow_right.svg';
import symbol63 from '../assets/icons/together_icon_cursor.svg';

import symbol64 from '../assets/icons/additional_benefits_key.svg';
import symbol65 from '../assets/icons/additional_benefits_papka.svg';
import symbol66 from '../assets/icons/additional_benefits_sso.svg';
import symbol67 from '../assets/icons/additional_benefits_team.svg';

import symbol68 from '../assets/icons/mobile_benefits_bg.svg';
import symbol69 from '../assets/icons/cases_business_icon.svg';
import symbol70 from '../assets/icons/cases_icon_check.svg';
import symbol71 from '../assets/icons/feedback_background.svg';

import symbol72 from '../assets/icons/footer_icon_tg.svg';
import symbol73 from '../assets/icons/footer_icon_vk.svg';
import symbol74 from '../assets/icons/dashboard_on_premise.svg';
import symbol75 from '../assets/icons/pruffme_logo.svg';
import symbol76 from '../assets/icons/dots_loader.svg';
import symbol77 from '../assets/icons/news_icon_left.svg';

import symbol78 from '../assets/icons/mobile_slide_conference_action.svg';
import symbol79 from '../assets/icons/mobile_slide_dashboard_action.svg';
import symbol80 from '../assets/icons/mobile_slide_webinar_action.svg';

import symbol81 from '../assets/icons/dashboard_mobile_figure_1.svg';
import symbol82 from '../assets/icons/feedback_background_mobile.svg';

import symbol83 from '../assets/icons/mobile_menu_icon.svg';
import symbol84 from '../assets/icons/pruffme_dark_logo.svg';
import symbol85 from '../assets/icons/mobile_menu_icon_dropdown.svg';


import getLandingNews from './news';



document.addEventListener('DOMContentLoaded', function () {
    addPruffmeLandingEventListeners();

    window.addEventListener('load', function () {
        initLandingPartnersScrolling();

        initDefferedImagesLoading();
        initSlidersControl();

        initWebinarSlider();
        initWebinarSliderTimer();
        initConferenceSlider();
        initDashboardSlider();
        initPruffmeLandingMobileCasesControl();
        initPruffmeLandingMobileEduwebControl();

        startLandingChangableFeedbackAnim();
    });

    loadPruffmeLandingScripts((result) => {
        if (result) {
            showLandingHeaderNews();
            startRollingBlocksAnimation();
            startLandingInteractiveBottomText();
            afterPruffmeScriptsLoaded();
            initPruffmeLandingNews();
        } else {
            console.error('Failed to load scripts')
        }
    });
})

function initPruffmeLandingNews() {
    let newsPerPage = 3;

    if (window.innerWidth < 1024) {
        newsPerPage = 2;
    }

    if (window.innerWidth < 664) {
        newsPerPage = 1;
    }

    getLandingNews(newsPerPage);
}


function getPruffmeLandingEnv() {
    let pruffme_current_landing_env = 'production';

    if (typeof __webpack_dev_server_client__ !== 'undefined') {
        pruffme_current_landing_env = 'dev-server';
    } else if (window.location.hostname.indexOf('localhost') >= 0) {
        pruffme_current_landing_env = 'local';
    }

    return pruffme_current_landing_env;
}


function makePruffmeLandingLink(value) {
    var url = "https://pruffme.com/";

    if (window.location.hostname.indexOf('localhost') >= 0) {
        var parts = location.pathname.split("/");
        var directory = parts[1];

        if (getPruffmeLandingEnv() === 'dev-server') {
            url = "http://localhost/pruffme/";
        } else if (typeof pruffmeapi != "undefined") {
            url = "https://localhost/pruffme/";
        } else {
            if (window.location.protocol == "https:") {
                url = "https://localhost/" + directory + "/";//"https://localhost.pruffme.com/pruffme/";
            } else {
                url = "http://localhost/" + directory + "/";
            }
        }
    } else if (window.location.hostname.indexOf('test.local.com') >= 0) {
        url = "https://localhost/pruffme/";
    } else if (window.location.hostname.indexOf('192.168.') >= 0) {
        url = "https://" + window.location.hostname + "/pruffme/";
    } else if ((window.location.host.indexOf("stage-front") >= 0) && (window.location.host.indexOf("pruffme.com") >= 0)) {
        url = "https://" + window.location.host + "/";
    } else if (window.location.hostname === 'pruffmelab.com') {
        url = "https://pruffmelab.com/";
    } else if ((window.location.host.indexOf("stage-admin") >= 0) && (window.location.host.indexOf("pruffmelab.com") >= 0)) {
        url = "https://" + window.location.host + "/";
    } else if (typeof global_pruffmelab_partner != "undefined") {
        url = "https://pruffmelab.com/";
    }
    url += value;

    return url;
}


function initLandingPartnersScrolling() {
    const container = document.getElementById('partners_scrolling_container');
    const outerContainer = document.querySelector('.pruffme_lp_partners_infinite_scrolling');
    const images = container.querySelectorAll('img.deferred-image');

    let loadedImagesCount = 0;
    let animationFrameId;

    // Load images and initialize the scroll only when all images are loaded
    images.forEach(img => {
        img.addEventListener('load', () => {
            loadedImagesCount++;
            if (loadedImagesCount === images.length) {
                initializeScroll();
            }
        });
    });

    function initializeScroll() {
        let startPosition = 0;
        let speed = 2; // Adjust speed as needed

        const elements = Array.from(container.querySelectorAll('.pruffme_lp_partners_infinite_scrolling_icons'));

        // Set initial positions
        elements.forEach((el, index) => {
            el.style.transform = `translateX(${startPosition + index * el.clientWidth}px)`;
        });

        function update() {
            elements.forEach(el => {
                const rect = el.getBoundingClientRect();
                let currentX = parseFloat(el.style.transform.replace('translateX(', '').replace('px)', ''));

                // Move element to the left
                currentX -= speed;

                // If element is out of view, move it to the end
                if (rect.right <= outerContainer.getBoundingClientRect().left) {
                    const lastElement = elements[elements.length - 1];
                    const lastElementX = parseFloat(lastElement.style.transform.replace('translateX(', '').replace('px)', ''));
                    currentX = lastElementX + lastElement.clientWidth;
                    container.appendChild(el); // Reorder element in the DOM
                    elements.push(elements.shift()); // Reorder array
                }

                el.style.transform = `translateX(${currentX}px)`;
            });

            animationFrameId = requestAnimationFrame(update);
        }

        // Start the animation
        function startAnimation() {
            if (!animationFrameId) {
                animationFrameId = requestAnimationFrame(update);
            }
        }

        // Stop the animation
        function stopAnimation() {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
                animationFrameId = null;
            }
        }

        // Create an Intersection Observer
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    startAnimation();
                } else {
                    stopAnimation();
                }
            });
        }, {
            root: null,
            threshold: 0
        });

        // Observe the outer container
        observer.observe(outerContainer);

        // Stop the animation when the container is not visible in the viewport
        document.addEventListener('visibilitychange', function () {
            if (document.hidden) {
                stopAnimation();
            } else {
                startAnimation();
            }
        });
    }
}

var pruffme_landing_resources_loaded = false;

function loadPruffmeResource(src, type, retries = 3, timeout = 5000) {
    return new Promise((resolve, reject) => {
        const head = document.head || document.getElementsByTagName('head')[0];
        let element;

        if (type === 'script') {
            element = document.createElement('script');
            element.type = 'text/javascript';
            element.src = src;
        } else if (type === 'style') {
            element = document.createElement('link');
            element.rel = 'stylesheet';
            element.href = src;
        } else {
            return reject(new Error('Unsupported resource type'));
        }

        const onError = () => {
            if (retries > 0) {
                console.warn(`Retrying ${src} (${retries} attempts left)`);
                setTimeout(() => {
                    loadPruffmeResource(src, type, retries - 1).then(resolve).catch(reject);
                }, timeout);
            } else {
                reject(new Error(`Failed to load resource: ${src}`));
            }
        };

        element.onload = () => resolve(src);
        element.onerror = onError;

        head.appendChild(element);
    });
}

function getPruffmeLandingLangFilePath() {
    let current_lang = null;
    let lang_path = "";
    if (!PRUFFME_CURRENT_LANG || PRUFFME_CURRENT_LANG === '%current_lang%') {
        current_lang = getCookie("lang")
        if (current_lang) {
            current_lang = current_lang.split(" ").join("");
        } else {
            current_lang = 'en';
        }
    } else {
        current_lang = PRUFFME_CURRENT_LANG;
    }


    if (current_lang.length == 0) {
        if (location.hostname == "ru.pruffme.com") {
            lang_path = "lang_ru.js";
        } else if (location.hostname == "en.pruffme.com") {
            lang_path = "lang_en.js";
        } else if (location.hostname == "de.pruffme.com") {
            lang_path = "lang_de.js";
        } else if (location.hostname == "fr.pruffme.com") {
            lang_path = "lang_fr.js";
        } else if (location.hostname == "es.pruffme.com") {
            lang_path = "lang_es.js";
        } else if (location.hostname == "pt.pruffme.com") {
            lang_path = "lang_pt.js";
        } else {
            var preferedLang = navigator.languages
                ? navigator.languages[0]
                : (navigator.language || navigator.userLanguage);
            var preferedLang = preferedLang.toLowerCase();

            if (typeof default_page_language != "undefined") {
                lang_path = "lang_" + default_page_language + ".js";
            } else if (preferedLang.indexOf("ru") >= 0) {
                lang_path = "lang_ru.js";
            } else if (preferedLang.indexOf("de") >= 0) {
                lang_path = "lang_de.js";
            } else if (preferedLang.indexOf("fr") >= 0) {
                lang_path = "lang_fr.js";
            } else if (preferedLang.indexOf("es") >= 0) {
                lang_path = "lang_es.js";
            } else if (preferedLang.indexOf("pt") >= 0) {
                lang_path = "lang_pt.js";
            } else {
                lang_path = "lang_ru.js";
            }
        }
    } else {
        lang_path = "lang_" + current_lang + ".js";
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    return lang_path;
}

function loadPruffmeLandingScripts(cb) {
    let sault = PRUFFME_CURRENT_REVISION !== '%revision%' ? `?${PRUFFME_CURRENT_REVISION}` : '';

    const firstResources = [
        loadPruffmeResource(makePruffmeLandingLink("assets/js/help.js" + sault), 'script'),
        loadPruffmeResource(makePruffmeLandingLink("assets/js/any.js" + sault), 'script'),
        loadPruffmeResource(makePruffmeLandingLink("assets/js/jquery/jquery.min.js" + sault), 'script'),
    ];

    if (getPruffmeLandingEnv() === 'dev-server') {
        firstResources.push(loadPruffmeResource(makePruffmeLandingLink("language/lang_ru.js"), 'script'));
    } else {
        const path = getPruffmeLandingLangFilePath();
        firstResources.push(loadPruffmeResource(makePruffmeLandingLink(`language/${path}` + sault), 'script'));
    }

    Promise.allSettled(firstResources).then(results => {
        const allFirstResourcesLoaded = results.every(result => result.status === 'fulfilled');

        if (allFirstResourcesLoaded) {
            const secondResources = [
                loadPruffmeResource(makePruffmeLandingLink("assets/js/templates.js" + sault), 'script'),
                loadPruffmeResource(makePruffmeLandingLink("assets/components/components.js" + sault), 'script'),
                loadPruffmeResource(makePruffmeLandingLink("assets/js/async/async.min.js" + sault), 'script'),
                loadPruffmeResource(makePruffmeLandingLink("assets/css/templates.css" + sault), 'style'),
                loadPruffmeResource(makePruffmeLandingLink("assets/components/components.css" + sault), 'style'),
            ];

            Promise.allSettled(secondResources).then(results => {
                const allSecondResourcesLoaded = results.every(result => result.status === 'fulfilled');

                if (allSecondResourcesLoaded) {
                    pruffme_landing_resources_loaded = true;
                    cb(true);
                } else {
                    console.error('Failed to load some secondary resources', results);
                    cb(false);
                }
            }).catch(error => {
                console.error('Error loading secondary resources', error);
                cb(false);
            });
        } else {
            console.error('Failed to load some primary resources', results);
            cb(false);
        }
    }).catch(error => {
        console.error('Error loading primary resources', error);
        cb(false);
    });
}

function afterPruffmeScriptsLoaded() {
    if (typeof pruffmeDropdown === 'function') {
        const component = pruffmeDropdown($('.pruffme_lp_header_central_products_box'), {
            content: translate(`<div class="pruffme_lp_header_products_dropdown">
                <div class="pruffme_lp_header_products_dropdown_card webinar_card">
                    <div class="pruffme_lp_header_products_dropdown_card_info">
                        <div class="pruffme_lp_header_products_dropdown_card_name">{pruffme.landing.header.webinar.name}</div>
                        <div class="pruffme_lp_header_products_dropdown_card_description">{pruffme.landing.header.webinar.description}</div>
                    </div>
                    <div class="pruffme_lp_header_products_dropdown_card_logo">
                        <picture>
                            <source type="image/webp"
                                srcset="${pruffme_image_path}/header_products_webinar.webp">
                            <img src="${pruffme_image_path}/header_products_webinar.png"
                                class="pruffme_lp_header_products_dropdown_card_image" alt="Webinar product">
                        </picture>
                    </div>
                </div>
                <div class="pruffme_lp_header_products_dropdown_card dashboard_card">
                    <div class="pruffme_lp_header_products_dropdown_card_info">
                        <div class="pruffme_lp_header_products_dropdown_card_name">{pruffme.landing.header.dashboards.name}</div>
                        <div class="pruffme_lp_header_products_dropdown_card_description">{pruffme.landing.header.dashboards.description}</div>
                    </div>
                    <div class="pruffme_lp_header_products_dropdown_card_logo">
                        <picture>
                            <source type="image/webp"
                                srcset="${pruffme_image_path}/header_products_dashboard.webp">
                            <img src="${pruffme_image_path}/header_products_dashboard.png"
                                class="pruffme_lp_header_products_dropdown_card_image" alt="dashboard product">
                        </picture>
                    </div>
                </div>
            </div>`),
            align: 'center',
            button: `<button class='pruffme_lp_header_button shadow'>${lang('pruffme.landing.header.products')}</button>`,
        })

        const langButton = $('#pruffme_landing_language');
        // const currentLang = lang('language').toUpperCase();

        // langButton.html(currentLang);

        component.find('.webinar_card').on('click', function () {
            if (pruffme_landing_resources_loaded) {
                openPruffmeLandingWebinarsDetails();
            }
        })



        component.find('.dashboard_card').on('click', function () {
            if (pruffme_landing_resources_loaded) {
                openPruffmeLandingDashboardDetails();
            }
        })

        addHeaderFlagDropdownToButton(langButton);
        langButton.find('.header-language-text-show-box').addClass('pruffme_lp_header_button').addClass('shadow')
    }


    addYandexMetrika(true);
    detectPayloadActions();
    checkSid(function (result) {
        if (result) {
            onLoginedActions();
        } else {
            onNoLoginedActions();
        }
    });
}

var pruffme_landing_preloaded_queue = [];

function addPruffmeLandingEventListeners() {
    // const loginButton = document.getElementById('pruffme_landing_login_button');

    const loginButtons = document.querySelectorAll('.pruffme_lp_login_button');
    const registerButtons = document.querySelectorAll('.pruffme_lp_register_button');
    const demoButtons = document.querySelectorAll('.pruffme_lp_demo_button');

    const webinarButtons = document.querySelectorAll('.pruffme_lp_create_webinar_button');
    const dashboardButtons = document.querySelectorAll('.pruffme_lp_create_dashboard_button');

    const webinarDetailsButton = document.querySelectorAll('.pruffme_lp_product_webinar_details');
    const dashboardDetailsButton = document.querySelectorAll('.pruffme_lp_product_dashboard_details');

    const onLoginClickActions = () => {
        if (!pruffme_landing_resources_loaded) {
            pruffme_landing_preloaded_queue.push('login');

            const loginBox = document.querySelector('.pruffme_lp_header_actions');
            loginBox.classList.add('show_login_loader');
        } else {
            openPopupLoginForm();
        }
    }

    const onRegisterClickActions = () => {
        if (!pruffme_landing_resources_loaded) {
            pruffme_landing_preloaded_queue.push('login');

            const loginBox = document.querySelector('.pruffme_lp_header_actions');
            loginBox.classList.add('show_login_loader');
        } else {
            if (typeof global_user === 'undefined') {
                openRegistrationForm();
            } else {
                const link = makeLink("cabinet");
                openURL(link);
            }
        }
    }

    const onDemoActionClicked = () => {
        if (!pruffme_landing_resources_loaded) {
            pruffme_landing_preloaded_queue.push('needdemo');
        } else {
            showLandingNeedDemoPopup();
            // if (typeof global_user === 'undefined') {

            // } else {
            //     const link = makeLink("cabinet");
            //     openURL(link);
            // }
        }
    }

    const onDashboardClickActions = () => {
        if (!pruffme_landing_resources_loaded) {
            pruffme_landing_preloaded_queue.push('login');
        } else {
            if (typeof global_user !== 'undefined') {
                createNewDashboardHeader();
            } else {
                openPopupLoginForm();
            }
        }
    }

    const onWebinarClickActions = () => {
        if (!pruffme_landing_resources_loaded) {
            pruffme_landing_preloaded_queue.push('login');
        } else {
            if (typeof global_user !== 'undefined') {
                createNewWebinarHeader();
            } else {
                openPopupLoginForm();
            }
        }
    }

    webinarDetailsButton.forEach(function (button) {
        button.addEventListener('click', function () {
            if (pruffme_landing_resources_loaded) {
                openPruffmeLandingWebinarsDetails();
            }
        });
    })

    dashboardDetailsButton.forEach(function (button) {
        button.addEventListener('click', function () {
            if (pruffme_landing_resources_loaded) {
                openPruffmeLandingDashboardDetails();
            }
        });
    })

    demoButtons.forEach(function (demoButton) {
        demoButton.addEventListener('click', onDemoActionClicked);
    })

    loginButtons.forEach(function (loginButton) {
        loginButton.addEventListener('click', onLoginClickActions);
    })

    registerButtons.forEach(function (loginButton) {
        loginButton.addEventListener('click', onRegisterClickActions);
    })

    dashboardButtons.forEach(function (dashboardButton) {
        dashboardButton.addEventListener('click', onDashboardClickActions);
    })

    webinarButtons.forEach(function (webinarButton) {
        webinarButton.addEventListener('click', onWebinarClickActions);
    })
}

function detectPayloadActions() {
    parseLandingQueryPayload(function (payload) {
        if (payload.error) {
            if (payload.error == "LOGIN_ERROR_NO_USER") {
                showAlert("Такого пользователя не существует");
                openPopupLoginForm();
            }
        } else if (typeof payload.action == "string") {
            if (payload.action == "registration") {
                registration_sms_object = payload.sms;
                openRegistrationUserForm();
            } else if (payload.action == "login") {
                openPopupLoginForm();
            }
        }
    })
}

function onNoLoginedActions() {
    const loginBox = document.querySelector('.pruffme_lp_header_actions');
    loginBox.classList.remove('show_login_loader');

    checkRestoreHash();

    showCookiePolicy();
    connectToImMessagesUnlogined();
    initPruffmeLandingMobileMenu();

    afterLoginActionsExecuted();
    checkPageNotificationHash();
}

function onLoginedActions() {
    $('body').addClass("is-logined");

    addGlobalUserInfoToHeader($('.pruffme_lp_header_actions_logined_box'));

    connectToImMessages();
    checkRestoreSpecialHash();

    afterLoginActionsExecuted();
    checkPageNotificationHash()
}

function afterLoginActionsExecuted() {
    if (pruffme_landing_preloaded_queue.length > 0) {
        if ($('.pruffme_modal').length === 0) {
            const currentAction = pruffme_landing_preloaded_queue[0];

            if (currentAction === 'login' && !global_user) {
                openPopupLoginForm();
            } else if (currentAction === 'needdemo' && !global_user) {
                showLandingNeedDemoPopup();
            }
        }
    }
}



function initDefferedImagesLoading() {
    const deferredImages = document.querySelectorAll('.deferred-image');

    // Заменяем атрибут 'data-src' на 'src' для начала загрузки изображений
    deferredImages.forEach(img => {
        const src = img.getAttribute('data-src');
        if (src) {
            img.setAttribute('src', src);
            img.removeAttribute('data-src');
        }

        // Проверяем соседние элементы с атрибутом 'data-srcset'
        const parent = img.parentNode;
        if (parent) {
            const siblingDeferredSrcset = parent.querySelectorAll('[data-srcset]');
            siblingDeferredSrcset.forEach(sibling => {
                const srcset = sibling.getAttribute('data-srcset');
                if (srcset) {
                    sibling.setAttribute('srcset', srcset);
                    sibling.removeAttribute('data-srcset');
                }
            });
        }
    });
}

function initSlidersControl() {
    const container = document.querySelector('.pruffme_lp_sliders_box');
    const buttons = document.querySelectorAll('.pruffme_lp_sliders_label');
    const cardElements = document.querySelectorAll('.pruffme_lp_slider_card');
    const sliderContent = document.querySelector('.pruffme_lp_sliders_content');
    const mobileContolButtons = document.querySelectorAll('.pruffme_lp_sliders_action_interactive_button_wrapper > button');

    let currentIndex = 0;
    let autoSwitchInterval = 3000;
    let isMouseOver = false;
    let userInteracted = false;

    buttons.forEach(button => {
        button.addEventListener('click', handleChangeSliderElement);
    });

    mobileContolButtons.forEach(button => {
        button.addEventListener('click', function () {
            startUserInteractedCounter();
        });
    })

    sliderContent.addEventListener('mouseenter', () => {
        isMouseOver = true;
    });

    sliderContent.addEventListener('mouseleave', () => {
        isMouseOver = false;
    });

    sliderContent.addEventListener('click', () => {
        startUserInteractedCounter();
    })

    function getSliderButtonType(button) {
        let type = null;

        if (button.classList.contains('label_dashboard')) {
            type = 'dashboard';
        } else if (button.classList.contains('label_webinar')) {
            type = 'webinar';
        } else if (button.classList.contains('label_conference')) {
            type = 'conference';
        }

        return type
    }

    function handleChangeSliderElement(event) {
        const button = event.currentTarget;

        const type = getSliderButtonType(button);

        startUserInteractedCounter();
        updateActiveButton(type);
    }

    let userInteractedId = null;
    function startUserInteractedCounter() {
        userInteracted = true;

        if (userInteractedId) {
            clearTimeout(userInteractedId);
        }

        userInteractedId = setTimeout(function () {
            userInteracted = false;
        }, 15000)
    }

    function updateActiveButton(type) {
        buttons.forEach(button => {
            button.classList.remove('active');

            if (button.classList.contains(`label_${type}`)) {
                button.classList.add('active');
            }
        });



        cardElements.forEach(card => {
            if (card.classList.contains(`card_${type}`)) {
                card.classList.remove('inactive_card');
                card.classList.add('active_card');
            } else {
                card.classList.add('inactive_card');
                card.classList.remove('active_card');
            }
            resizeLandingDrawingCanvas();
        });

        container.setAttribute('data-current-slide', type);
    }

    cardElements.forEach(card => {
        card.addEventListener('click', function () {
            if (card.classList.contains('inactive_card')) {
                let card_type = '';

                if (card.classList.contains('card_dashboard')) {
                    card_type = 'dashboard';
                } else if (card.classList.contains('card_conference')) {
                    card_type = 'conference';
                } else if (card.classList.contains('card_webinar')) {
                    card_type = 'webinar';
                }


                console.log(card_type);

                updateActiveButton(card_type);
            }
        })
    });

    function autoSwitch() {
        if (!isMouseOver && !userInteracted) {
            currentIndex = (currentIndex + 1) % buttons.length;

            const type = getSliderButtonType(buttons[currentIndex]);

            updateActiveButton(type);
        }
    }

    const intervalId = setInterval(autoSwitch, autoSwitchInterval);
}

function initWebinarSlider() {
    // QUIZ
    const checkboxes = document.querySelectorAll('input[name="landing_quiz_checkbox"]');

    checkboxes.forEach(function (checkbox) {
        checkbox.addEventListener('change', function () {
            document.querySelector('.pruffme_lp_slider_card_webinar_quiz').classList.add('hidden');
            document.querySelector('.pruffme_lp_slider_card_webinar_quiz_results').classList.remove('hidden');
        });
    });

    const dropdownBtn = document.querySelector('#webinar_card_action_button');
    const dropdownContent = document.querySelector('.pruffme_lp_slider_card_webinar_action_dropdown');

    dropdownBtn.addEventListener('mouseenter', function () {
        dropdownContent.style.display = 'block';
    });

    dropdownBtn.addEventListener('mouseleave', function () {
        dropdownContent.style.display = 'none';
    });

    dropdownContent.addEventListener('mouseenter', function () {
        dropdownContent.style.display = 'block';
    });

    dropdownContent.addEventListener('mouseleave', function () {
        dropdownContent.style.display = 'none';
    });

    //REACTIONS
    const buttons = document.querySelectorAll('.pruffme_lp_slider_card_webinar_action_dropdown_item');
    const mobileContolButton = document.querySelector('.pruffme_lp_sliders_action_interactive_button.action_for_webinar');
    // Добавляем обработчик события на каждую кнопку
    buttons.forEach(function (item) {
        item.addEventListener('click', function (event) {
            let button = event.currentTarget;

            if (button) {
                const action = button.dataset.action;

                if (action) {
                    handleShowLandingWebinarReaction(action);
                }
            }
        });
    });

    const reactions = getAvailableLandingWebinarReactions();
    mobileContolButton.addEventListener('click', function () {
        const randomIndex = Math.floor(Math.random() * reactions.length);
        const randomReaction = reactions[randomIndex];

        handleShowLandingWebinarReaction(randomReaction);
    })
}

function handleShowLandingWebinarReaction(action) {
    showFlyingReaction(action, 5);
    showReactionsToVideo(action);
}

function showFlyingReaction(type, repeat) {
    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const b = Math.floor(Math.random() * 100) + 1;
    const animationArray = ["flowOne", "flowTwo", "flowThree"];
    const c = (Math.random() * (1.6 - 1.2) + 1.2).toFixed(1);
    const randomWidth = getRandomInt(17, 37);
    const animation = `${animationArray[Math.floor(Math.random() * 3)]} ${c}s linear`;

    const object = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    object.classList.add(`flying-reaction`, `flying-reaction-part-${b}`, `webinar_reaction_icon_${type}`);
    object.style.width = `${randomWidth}px`;
    object.style.height = `${randomWidth}px`;
    object.style.animation = animation;

    const useElement = document.createElementNS("http://www.w3.org/2000/svg", "use");
    useElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", `#webinar_card_icon_reaction_${type}`);
    object.appendChild(useElement);

    document.querySelector(".pruffme_lp_slider_card_webinar_room_flying_reactions").appendChild(object);
    object.style.display = 'block';

    setTimeout(() => {
        object.remove();
    }, c * 900);

    if (typeof repeat === 'number' && repeat > 0) {
        setTimeout(showFlyingReaction, 100, type, repeat - 1);
    }
}
function showReactionsToVideo(type) {
    const template = document.querySelector('.pruffme_lp_webinar_publisher_card.active-videobox');

    if (template) {
        const throttledShow = lpthrottle(() => showVideoReaction(type), `video_reaction_${type}`, 5000);
        const debouncedHide = lpdebounce(clearVideoReactions, `video_reaction_hide_${type}`, 5000);
        throttledShow();
        debouncedHide();
    }
}

function getAvailableLandingWebinarReactions() {
    return ['boom', 'fire', 'heart', 'thumbsup', 'smile', 'clap'];
}

function clearVideoReactions() {
    const template = document.querySelector('.pruffme_lp_webinar_publisher_card.active-videobox');
    const reactions = getAvailableLandingWebinarReactions();

    if (template) {
        template.classList.remove('video-has-reaction');
        reactions.forEach(item => {
            template.classList.remove(`reaction-${item}`);
        });
    }
}

function showVideoReaction(type) {
    const object = document.querySelector('.pruffme_lp_webinar_publisher_card.active-videobox');
    clearVideoReactions();
    if (object) {
        object.classList.add(`reaction-${type}`, 'video-has-reaction');
    }
}

// Функция вызывается не более одного раза в указанный период времени
const lpThrottleObjects = {};

function lpthrottle(func, name, milliseconds) {
    if (!lpThrottleObjects[name]) {
        lpThrottleObjects[name] = { lastCall: 0 };
    }
    return function (...args) {
        const now = Date.now();
        if (lpThrottleObjects[name].lastCall + milliseconds < now) {
            lpThrottleObjects[name].lastCall = now;
            return func.apply(this, args);
        }
    };
}

// Все вызовы будут игнорироваться до тех пор, пока они не прекратятся на определённый период времени.
// Только после этого функция будет вызвана
const lpDebounceObjects = {};

function lpdebounce(func, name, interval) {
    if (!lpDebounceObjects[name]) {
        lpDebounceObjects[name] = -1;
    }
    return function (...args) {
        clearTimeout(lpDebounceObjects[name]);
        lpDebounceObjects[name] = setTimeout(() => {
            func.apply(this, args);
        }, interval);
    };
}

function initWebinarSliderTimer() {
    updateTimer();
}

function formatLPTimerTime(timeInSeconds) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

// Функция для обновления значения таймера и сохранения текущего времени в локальном хранилище
function updateTimer() {
    const initialTime = 3600;

    const timerElements = document.querySelectorAll('.pruffme_lp_slider_card_webinar_room_header_counter_text');
    let currentTime = localStorage.getItem('webinarTimer') ? parseInt(localStorage.getItem('webinarTimer')) : 3600; // Время по умолчанию: 01:00:00 (в секундах);

    timerElements.forEach(timerElement => {
        if (currentTime) {
            timerElement.textContent = formatLPTimerTime(currentTime);
        } else {
            timerElement.textContent = formatLPTimerTime(initialTime);
        }
    });

    setInterval(() => {
        currentTime++;
        if (currentTime > 9000) { // Если текущее время больше 02:30:00 (в секундах), сбрасываем таймер
            currentTime = initialTime;
        }
        localStorage.setItem('webinarTimer', currentTime); // Сохраняем текущее время в локальном хранилище

        timerElements.forEach(timerElement => {
            if (timerElement) {
                timerElement.textContent = formatLPTimerTime(currentTime);
            }
        });
    }, 1000);
}

function initConferenceSlider() {
    const button = document.getElementById('conference_card_action_change_bg');
    const container = document.querySelector('.pruffme_lp_slider_card_conference_publishers_box_content');
    const mobileContolButton = document.querySelector('.pruffme_lp_sliders_action_interactive_button.action_for_conference');

    let current_variant = 0;

    button.addEventListener('click', changeConferenceBg);
    mobileContolButton.addEventListener('click', changeConferenceBg);

    function changeConferenceBg() {
        if (current_variant == 0) {
            container.classList.add('show_alternative_1');
            current_variant = 1;
        } else if (current_variant == 1) {
            container.classList.add('show_alternative_2');
            container.classList.remove('show_alternative_1');
            current_variant = 2;
        } else if (current_variant == 2) {
            container.classList.add('show_alternative_3');
            container.classList.remove('show_alternative_2');
            current_variant = 3;
        } else {
            container.classList.remove('show_alternative_1');
            container.classList.remove('show_alternative_2');
            container.classList.remove('show_alternative_3');
            current_variant = 0;
        }
    }


    const handupButton = document.getElementById('conference_card_action_handup');
    const videoBox = document.querySelector('.pruffme_lp_slider_card_conference_publisher_card_box.active-videobox');

    handupButton.addEventListener('click', function () {
        setConferenceHandupReaction();
    })



    let timeoutId = null;

    function setConferenceHandupReaction() {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        videoBox.classList.add('video-has-reaction');

        timeoutId = setTimeout(function () {
            videoBox.classList.remove('video-has-reaction');
        }, 5000)
    }
}

function initDashboardSlider() {
    initDashboardCursors();
    initDashboardCanvas();
    initDashboardMobileFigures();
}


function initDashboardCursors() {
    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function getRandomPosition(element, container) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        const maxX = containerRect.width - elementRect.width;
        const maxY = containerRect.height - elementRect.height;
        return {
            x: getRandomInt(0, maxX),
            y: getRandomInt(0, maxY)
        };
    }

    function animateElement(element, container, minSpeed, maxSpeed) {
        const newPosition = getRandomPosition(element, container);
        const duration = getRandomInt(minSpeed, maxSpeed) * 1000; // Convert to milliseconds
        const bezierCurve = `cubic-bezier(${Math.random()}, ${Math.random()}, ${Math.random()}, ${Math.random()})`;

        element.style.transition = `transform ${duration}ms ${bezierCurve}`;
        element.style.transform = `translate(${newPosition.x}px, ${newPosition.y}px)`;

        // Random pause duration between movements
        const pauseDuration = getRandomInt(0, 3000); // Pause between 0 and 3 seconds

        setTimeout(() => {
            setTimeout(() => animateElement(element, container, minSpeed, maxSpeed), pauseDuration);
        }, duration);
    }

    const container = document.querySelector('.pruffme_lp_slider_card_dashboard_wrapper');
    const elements = document.querySelectorAll('.pruffme_lp_slider_card_dashboard_cursor');

    const minSpeed = 2; // Minimum speed in seconds
    const maxSpeed = 5; // Maximum speed in seconds

    elements.forEach(element => {
        animateElement(element, container, minSpeed, maxSpeed);
    });
}

function initDashboardCanvas() {
    const drawContainer = document.getElementById('dashboard_draw_container');
    const screenContainer = document.getElementById('dashboard_screen_container');

    // Создание canvas элементов
    const drawCanvas = document.createElement('canvas');
    drawCanvas.id = 'dashboard_draw_canvas';
    drawContainer.appendChild(drawCanvas);

    const screenCanvas = document.createElement('canvas');
    screenCanvas.id = 'dashboard_screen_canvas';
    screenContainer.appendChild(screenCanvas);

    const drawContext = drawCanvas.getContext('2d');
    const screenContext = screenCanvas.getContext('2d');

    let isDrawing = false;
    let lastX = 0;
    let lastY = 0;

    window.addEventListener('resize', resizeLandingDrawingCanvas);
    setTimeout(() => { resizeLandingDrawingCanvas() }, 500);

    drawCanvas.addEventListener('mouseenter', () => {
        drawCanvas.classList.add('brush-cursor');
    });

    drawCanvas.addEventListener('mouseleave', () => {
        drawCanvas.classList.remove('brush-cursor');
    });

    drawCanvas.addEventListener('mousedown', (e) => {
        isDrawing = true;
        [lastX, lastY] = [e.offsetX, e.offsetY];
    });

    drawCanvas.addEventListener('mousemove', draw);
    drawCanvas.addEventListener('mouseup', () => isDrawing = false);
    drawCanvas.addEventListener('mouseout', () => isDrawing = false);

    function draw(e) {
        if (!isDrawing) return;
        drawContext.strokeStyle = 'rgb(245, 226, 59)';
        drawContext.lineJoin = 'round';
        drawContext.lineCap = 'round';
        drawContext.lineWidth = 5;

        drawContext.beginPath();
        drawContext.moveTo(lastX, lastY);
        drawContext.lineTo(e.offsetX, e.offsetY);
        drawContext.stroke();
        [lastX, lastY] = [e.offsetX, e.offsetY];

        // Обновляем screenCanvas копированием содержимого drawCanvas
        screenContext.clearRect(0, 0, screenCanvas.width, screenCanvas.height);
        screenContext.drawImage(drawCanvas, 0, 0);
    }
}

function initDashboardMobileFigures() {
    let currentFigure = 0;
    const totalFigures = 1;
    const container = document.querySelector('.pruffme_lp_slider_card_dashboard_mobile_figures');

    const mobileContolButton = document.querySelector('.pruffme_lp_sliders_action_interactive_button.action_for_dashboard');

    mobileContolButton.addEventListener('click', function () {
        let nextFigure = currentFigure + 1;

        if (nextFigure > totalFigures) {
            nextFigure = 0;
        }

        currentFigure = nextFigure;

        container.setAttribute('data-figure', nextFigure);
    })
}

function resizeLandingDrawingCanvas() {
    const drawCanvas = document.getElementById('dashboard_draw_canvas');
    const drawContainer = drawCanvas.parentElement;
    const screenCanvas = document.getElementById('dashboard_screen_canvas');
    const screenContainer = screenCanvas.parentElement;

    drawCanvas.width = drawContainer.clientWidth;
    drawCanvas.height = drawContainer.clientHeight;

    screenCanvas.width = screenContainer.clientWidth;
    screenCanvas.height = screenContainer.clientHeight;
}

function startRollingBlocksAnimation() {
    if (typeof lang !== 'function') {
        return;
    }

    const container = document.getElementById('rolling_benefits_block');
    // const rootStyles = getComputedStyle(document.documentElement);
    // const elementHeightStr = rootStyles.getPropertyValue('--lp-rolling-benefit-height');
    // const elementHeight = parseFloat(elementHeightStr);
    const elementHeight = 72;

    const totalItems = 19; // Количество элементов

    // Функция для создания и добавления элементов в контейнер
    function createAndAppendItems() {
        for (let i = 1; i <= totalItems; i++) {
            const item = document.createElement('div');
            item.className = 'pruffme_lp_rolling_benefits_item';

            const innerItem = document.createElement('div');
            innerItem.className = 'pruffme_lp_rolling_benefits_item_inner';
            innerItem.textContent = lang(`pruffme.landing.rolling_benefits.item.${i}`);

            item.appendChild(innerItem);
            container.appendChild(item);
        }
    }

    createAndAppendItems();

    const items = container.getElementsByClassName('pruffme_lp_rolling_benefits_item');
    const centerIndex = 4; // Индекс пятого элемента (0-based index)

    // Устанавливаем начальное положение контейнера
    function centerElement() {
        const offset = (centerIndex - 3) * elementHeight; // Сдвиг для центровки пятого элемента
        container.style.transform = `translateY(-${offset}px)`;
    }

    centerElement();

    let currentIndex = centerIndex;

    setInterval(() => {
        const firstItem = items[0];

        // Меняем высоту первого элемента до 0
        firstItem.style.height = '0';

        const activeItem = container.querySelector('.active_item');
        if (activeItem) {
            activeItem.classList.remove('active_item');
        }

        currentIndex = centerIndex;
        items[currentIndex].classList.add('active_item');

        setTimeout(() => {
            // Убираем первый элемент и добавляем его в конец
            container.appendChild(firstItem);
            firstItem.style.height = `${elementHeight}px`; // Восстанавливаем высоту

            // Обновляем класс active_item


            // Перецентровываем контейнер
            // centerElement();
        }, 300);

    }, 1000);
}

function startLandingChangableFeedbackAnim() {
    const container = document.querySelector('.pruffme_lp_feedback_block_reviews_inner');
    const cards = document.querySelectorAll('.pruffme_lp_feedback_block_review_card');
    let currentIndex = 0;
    let isPaused = false;
    let userInteracted = false;
    const animationDelay = 8000; // 8 секунд для автоматического переключения
    const pauseDelay = 16000; // 30 секунд паузы после взаимодействия пользователя

    const moveToNextCard = () => {
        if (isPaused) return;
        currentIndex = (currentIndex + 1) % cards.length;
        updateTransform();
    };

    const updateTransform = () => {
        const offset = -1 * currentIndex * container.parentElement.offsetWidth; // Ширина карточки + отступ
        container.style.transform = `translateX(${offset}px)`;
    };

    const startAnimation = () => {
        isPaused = false;
        animationInterval = setInterval(moveToNextCard, animationDelay);
    };

    const stopAnimation = () => {
        isPaused = true;
        clearInterval(animationInterval);
    };

    let animationInterval = setInterval(moveToNextCard, animationDelay);

    let lastIntervalId = null;

    cards.forEach((card, cardIndex) => {
        const indices = card.querySelectorAll('.pruffme_lp_feedback_block_review_card_index');
        indices.forEach((indexElement, index) => {
            indexElement.addEventListener('click', (event) => {
                event.stopPropagation(); // Остановка всплытия события, чтобы не сработал родительский обработчик
                userInteracted = true;
                currentIndex = index;
                updateTransform();
                stopAnimation();

                if (lastIntervalId) {
                    clearTimeout(lastIntervalId);
                }

                lastIntervalId = setTimeout(() => {
                    if (userInteracted) {
                        userInteracted = false;
                        startAnimation();
                    }
                }, pauseDelay);
            });
        });
    });
}

function startLandingInteractiveBottomText() {
    const changableVariants = [];

    for (let i = 1; i <= 4; i++) {
        changableVariants.push(lang(`pruffme.landing.interactive_text.variant.${i}`));
    }

    const changableElement = document.querySelector('.pruffme_lp_interactive_text_changable');
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    async function printAndEraseText(text) {
        for (let i = 0; i < text.length; i++) {
            changableElement.textContent += text[i];
            await delay(100); // Adjust delay time (in milliseconds) for typing effect
        }

        await delay(1000); // Wait after printing before erasing

        for (let i = 0; i < text.length; i++) {
            changableElement.textContent = changableElement.textContent.slice(0, -1);
            await delay(50); // Adjust delay time (in milliseconds) for erasing effect
        }
    }

    async function cycleVariants() {
        for (let variant of changableVariants) {
            await printAndEraseText(variant);
            await delay(500); // Adjust delay time between variants (in milliseconds)
        }
        // After cycling through all variants, you can restart or perform any other actions
        cycleVariants(); // This restarts the cycle
    }

    // Intersection Observer to start animation when element is visible
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Start cycling through variants when element is visible
                cycleVariants();
                observer.disconnect(); // Stop observing once started
            }
        });
    });

    observer.observe(changableElement);
}

function initPruffmeLandingMobileCasesControl() {
    const buttons = document.querySelectorAll('.pruffme_lp_cases_block_mobile_navigation > .pruffme_lp_block_mobile_navigation_item');
    const cards = document.querySelectorAll('.pruffme_lp_cases_block_mobile_card');

    buttons.forEach(button => {
        button.addEventListener('click', function () {
            cards.forEach(card => {
                card.classList.remove('active_card');
            });

            buttons.forEach(btn => {
                btn.classList.remove('active_button');
            });

            const targetCard = document.querySelector('.card_' + this.dataset.target);
            if (targetCard) {
                targetCard.classList.add('active_card');
            }

            this.classList.add('active_button');
        });
    });
}

function initPruffmeLandingMobileEduwebControl() {
    const buttons = document.querySelectorAll('.pruffme_lp_eduweb_block_navigation_mobile > .pruffme_lp_block_mobile_navigation_item');
    const cards = document.querySelectorAll('.pruffme_lp_eduweb_block_card');

    buttons.forEach(button => {
        button.addEventListener('click', function () {
            cards.forEach(card => {
                card.classList.remove('active_card');
            });

            buttons.forEach(btn => {
                btn.classList.remove('active_button');
            });

            const targetCard = document.querySelector('.pruffme_lp_eduweb_block_card.card_' + this.dataset.target);
            if (targetCard) {
                targetCard.classList.add('active_card');
            }

            this.classList.add('active_button');
        });
    });
}

function initPruffmeLandingMobileMenu() {
    const button = $('#pruffme_landing_open_mobile_menu');

    const modal = pruffmeMobileModal({
        content: $(translate(`<div class="pruffme_lp_mobile_menu">
                <div class="pruffme_lp_mobile_menu_inner">
                    <div class="pruffme_lp_mobile_menu_header">
                        <div class="pruffme_lp_header_logo_box">
                            <svg class="pruffme_lp_header_logo">
                                <use xlink:href="#pruffme_dark_logo"></use>
                            </svg>
                        </div>
                        <button class="pruffme_button pruffme_icon_button small ghost hover_off" id="pruffme_landing_open_close_menu">
                        	<div class="pruffme_icon pruffme_icon_cross_thin"></div>
                        </button>
                    </div>
                    <div class="pruffme_lp_mobile_menu_content">
                        <div class="pruffme_lp_mobile_menu_line expandable">
                            <div class="pruffme_lp_mobile_menu_line_header">
                                <div class="pruffme_lp_mobile_menu_line_name">{pruffme.landing.header.products}</div>
                                <div class="pruffme_lp_mobile_menu_line_lang_wrapper">
                                    <div class="pruffme_lp_mobile_menu_line_expand_icon">
                                        <svg class="pruffme_landing_icon">
                                            <use xlink:href="#mobile_menu_icon_dropdown"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="pruffme_lp_mobile_menu_line_content content_products">
                                <div class="pruffme_lp_mobile_menu_product product_webinar">
                                    <div class="pruffme_lp_mobile_menu_product_info">
                                        <div class="pruffme_lp_mobile_menu_product_name">{pruffme.landing.header.webinar.name}</div>
                                        <div class="pruffme_lp_mobile_menu_product_description">{pruffme.landing.header.webinar.description}</div>
                                    </div>
                                    <picture class="pruffme_lp_mobile_menu_product_logo">
                                        <source type="image/webp"
                                            srcset="${pruffme_image_path}/header_products_webinar_mobile.webp">
                                        <img src="${pruffme_image_path}/header_products_webinar_mobile.png"
                                            class="pruffme_lp_header_products_dropdown_card_image" alt="Webinar product">
                                    </picture>
                                </div>
                                <div class="pruffme_lp_mobile_menu_product product_dashboard">
                                    <div class="pruffme_lp_mobile_menu_product_info">
                                        <div class="pruffme_lp_mobile_menu_product_name">{pruffme.landing.header.dashboards.name}</div>
                                        <div class="pruffme_lp_mobile_menu_product_description">{pruffme.landing.header.dashboards.description}</div>
                                    </div>
                                    <picture class="pruffme_lp_mobile_menu_product_logo">
                                        <source type="image/webp"
                                            srcset="${pruffme_image_path}/header_products_dashboard_mobile.webp">
                                        <img src="${pruffme_image_path}/header_products_dashboard_mobile.png"
                                            class="pruffme_lp_header_products_dropdown_card_image" alt="dashboard product">
                                    </picture>
                                </div>
                            </div>
                        </div>

                        <a class="pruffme_lp_mobile_menu_line" href="https://pruffme.com/pages/prices/">
                            <div class="pruffme_lp_mobile_menu_line_header">
                                <div class="pruffme_lp_mobile_menu_line_name">{pruffme.landing.header.tariff}</div>
                            </div>
                        </a>
                        <a class="pruffme_lp_mobile_menu_line" href="https://pruffme.com/pages/support/">
                            <div class="pruffme_lp_mobile_menu_line_header">
                                <div class="pruffme_lp_mobile_menu_line_name">{pruffme.landing.header.support}</div>
                            </div>
                        </a>
                        <div class="pruffme_lp_mobile_menu_line expandable">
                            <div class="pruffme_lp_mobile_menu_line_header">
                                <div class="pruffme_lp_mobile_menu_line_name">{pruffme.landing.header.language}</div>
                                <div class="pruffme_lp_mobile_menu_line_lang_wrapper">
                                    <span>${lang('language').toUpperCase()}</span>
                                    <div class="pruffme_lp_mobile_menu_line_expand_icon">
                                        <svg class="pruffme_landing_icon">
                                            <use xlink:href="#mobile_menu_icon_dropdown"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="pruffme_lp_mobile_menu_line_content content_languages"></div>
                        </div>
                    </div>
                    <div class="pruffme_lp_mobile_menu_actions">
                        <button class="pruffme_lp_action_button dark" id="pruffme_landing_mobile_menu_trial">{pruffme.landing.header.menu.trial}</button>
                        <button class="pruffme_lp_action_button" id="pruffme_landing_mobile_menu_demo">{pruffme.landing.header.menu.demo}</button>
                    </div>
                </div>
            </div>`)),
        useHeader: false,
    })

    modal.find('#pruffme_landing_open_close_menu').on('click', function () {
        closeModal()
    })

    modal.find('.pruffme_lp_mobile_menu_line.expandable').each(function () {
        const header = $(this).find('.pruffme_lp_mobile_menu_line_header');
        const contentEl = $(this).find('.pruffme_lp_mobile_menu_line_content');

        header.on('click', function () {
            contentEl.toggleClass('expanded');
        })
    });

    button.on('click', function () {
        modal.native().openModal()
    })

    modal.find('.product_webinar').on('click', function () {
        closeModal();
        openPruffmeLandingWebinarsDetails();
    })

    modal.find('.product_dashboard').on('click', function () {
        closeModal();
        openPruffmeLandingDashboardDetails();
    })

    modal.find('#pruffme_landing_mobile_menu_demo').on('click', function () {
        closeModal();
        showLandingNeedDemoPopup();
    })

    modal.find('#pruffme_landing_mobile_menu_trial').on('click', function () {
        closeModal();
        openRegistrationForm();
    })

    const langContainer = modal.find('.content_languages');
    getAvailableLanguagesList().forEach(function (item) {
        const langTemp =
            $(`<div class="pruffme_lp_mobile_menu_line_lang_item" onclick="changeLanguage('${item.lang}')">
                <div class="header-flag-dropdown-icon ${item.flag}"></div>
                <span>${item.lang.toUpperCase()}</span>
            </div>`);

        if (item.lang === lang('language')) {
            langTemp.addClass('active');
        }

        langContainer.append(langTemp);
    });

    function closeModal() {
        modal.native().closeModal();;
    }
}

function openPruffmeLandingDashboardDetails() {
    window.location.href = lang('pruffme.main.buttons.details.dashboards');
}

function openPruffmeLandingWebinarsDetails() {
    window.location.href = lang('pruffme.main.buttons.details.webinars');

}

// function getLandingHeaderNews() {
//     try {
//         const content = JSON.parse(header_news_data);

//         return content;
//     } catch (err) {
//         console.warn(err);
//         return null;
//     }
// }

function getLandingHeaderNews(cb) {
    post('landing-header-news', {}, function (response) {
        if (response.result && Array.isArray(response.result) && response.result[0]) {
            const news = response.result[0];

            try {
                const content = JSON.parse(news.content);
                news.content = content;

                cb(news);
            } catch (err) {
                console.warn(err);
                cb(null);
            }
        } else {
            cb(null);
        }
    })
}

function showLandingHeaderNews() {
    getLandingHeaderNews(function (news) {
        console.log(news);

        if (news) {
            document.querySelector('body').classList.add('has_landing_news');

            const newsButton = document.querySelector('#pruffme_landing_header_news_button');
            const newsText = document.querySelector('#pruffme_landing_header_news_text');
            const newsContainer = document.querySelector('#pruffme_landing_header_news_container');

            if (newsText && news.content && news.content.content) {
                newsText.innerHTML = news.content.content;
            }

            if (newsButton && news.content && news.content.button_text) {
                newsButton.innerHTML = news.content.button_text;
            }

            if (news.link) {
                newsButton.setAttribute('href', news.link);
                newsButton.setAttribute('target', '_blank');
            }

            if (newsContainer) {
                if (news.color) {
                    newsContainer.style.setProperty("--pruffme_landing_header_news_color", news.color);
                }

                if (news.content && news.content.text_color) {
                    newsContainer.style.setProperty("--pruffme_landing_header_news_text_color", news.content.text_color);
                }

                if (news.content && news.content.button_color) {
                    newsContainer.style.setProperty("--pruffme_landing_header_news_button_color", news.content.button_color);
                }

                if (news.content && news.content.button_hover_color) {
                    newsContainer.style.setProperty("--pruffme_landing_header_news_button_hover_color", news.content.button_hover_color);
                }
            }
        }
    });
}